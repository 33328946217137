.m-0 {
	margin: 0rem;
}

.m-1 {
	margin: 1rem;
}

.m-2 {
	margin: 2rem;
}

.m-3 {
	margin: 3rem;
}

.m-4 {
	margin: 4rem;
}

.m-5 {
	margin: 5rem;
}

.m-6 {
	margin: 6rem;
}

.mt-0 {
	margin-top: 0rem;
}

.mt-1 {
	margin-top: 1rem;
}

.mt-2 {
	margin-top: 2rem;
}

.mt-3 {
	margin-top: 3rem;
}

.mt-4 {
	margin-top: 4rem;
}

.mt-5 {
	margin-top: 5rem;
}

.mt-6 {
	margin-top: 6rem;
}

.mr-0 {
	margin-right: 0rem;
}

.mr-1 {
	margin-right: 1rem;
}

.mr-2 {
	margin-right: 2rem;
}

.mr-3 {
	margin-right: 3rem;
}

.mr-4 {
	margin-right: 4rem;
}

.mr-5 {
	margin-right: 5rem;
}

.mr-6 {
	margin-right: 6rem;
}

.mb-0 {
	margin-bottom: 0rem;
}

.mb-1 {
	margin-bottom: 1rem;
}

.mb-2 {
	margin-bottom: 2rem;
}

.mb-3 {
	margin-bottom: 3rem;
}

.mb-4 {
	margin-bottom: 4rem;
}

.mb-5 {
	margin-bottom: 5rem;
}

.mb-5px {
	margin-bottom: 5px;
}

.mb-6 {
	margin-bottom: 6rem;
}

.ml-0 {
	margin-left: 0rem;
}

.ml-1 {
	margin-left: 1rem;
}

.ml-2 {
	margin-left: 2rem;
}

.ml-3 {
	margin-left: 3rem;
}

.ml-4 {
	margin-left: 4rem;
}

.ml-5 {
	margin-left: 5rem;
}

.ml-6 {
	margin-left: 6rem;
}

.m-0-imp {
	margin: 0rem !important;
}

.m-1-imp {
	margin: 1rem !important;
}

.m-2-imp {
	margin: 2rem !important;
}

.m-3-imp {
	margin: 3rem !important;
}

.m-4-imp {
	margin: 4rem !important;
}

.m-5-imp {
	margin: 5rem !important;
}

.m-6-imp {
	margin: 6rem !important;
}

.mt-0-imp {
	margin-top: 0rem !important;
}

.mt-1-imp {
	margin-top: 1rem !important;
}

.mt-2-imp {
	margin-top: 2rem !important;
}

.mt-3-imp {
	margin-top: 3rem !important;
}

.mt-4-imp {
	margin-top: 4rem !important;
}

.mt-5-imp {
	margin-top: 5rem !important;
}

.mt-6-imp {
	margin-top: 6rem !important;
}

.mr-0-imp {
	margin-right: 0rem !important;
}

.mr-1-imp {
	margin-right: 1rem !important;
}

.mr-2-imp {
	margin-right: 2rem !important;
}

.mr-3-imp {
	margin-right: 3rem !important;
}

.mr-4-imp {
	margin-right: 4rem !important;
}

.mr-5-imp {
	margin-right: 5rem !important;
}

.mr-6-imp {
	margin-right: 6rem !important;
}

.mb-0-imp {
	margin-bottom: 0rem !important;
}

.mb-1-imp {
	margin-bottom: 1rem !important;
}

.mb-2-imp {
	margin-bottom: 2rem !important;
}

.mb-3-imp {
	margin-bottom: 3rem !important;
}

.mb-4-imp {
	margin-bottom: 4rem !important;
}

.mb-5-imp {
	margin-bottom: 5rem !important;
}

.mb-6-imp {
	margin-bottom: 6rem !important;
}

.ml-0-imp {
	margin-left: 0rem !important;
}

.ml-1-imp {
	margin-left: 1rem !important;
}

.ml-2-imp {
	margin-left: 2rem !important;
}

.ml-3-imp {
	margin-left: 3rem !important;
}

.ml-4-imp {
	margin-left: 4rem !important;
}

.ml-5-imp {
	margin-left: 5rem !important;
}

.ml-6-imp {
	margin-left: 6rem !important;
}

//margins with media queries

.ml10 {
	margin-left: 10px;
}

.ml5 {
	margin-left: 5px;
}

.mr10 {
	margin-right: 10px;
}

.mr5 {
	margin-right: 5px;
}

.mt10 {
	margin-top: 10px;
}

.mt5 {
	margin-top: 5px;
}

.mb10 {
	margin-bottom: 10px;
}

.mb5 {
	margin-bottom: 5px;
}

//padding

.p-0 {
	padding: 0rem;
}

.p-1 {
	padding: 1rem;
}

.p-2 {
	padding: 2rem;
}

.p-3 {
	padding: 3rem;
}

.p-4 {
	padding: 4rem;
}

.p-5 {
	padding: 5rem;
}

.p-6 {
	padding: 6rem;
}

.pt-0 {
	padding-top: 0rem;
}

.pt-1 {
	padding-top: 1rem;
}

.pt-2 {
	padding-top: 2rem;
}

.pt-3 {
	padding-top: 3rem;
}

.pt-4 {
	padding-top: 4rem;
}

.pt-5 {
	padding-top: 5rem;
}

.pt-6 {
	padding-top: 6rem;
}

.pr-0 {
	padding-right: 0rem;
}

.pr-1 {
	padding-right: 1rem;
}

.pr-2 {
	padding-right: 2rem;
}

.pr-3px {
	padding-right: 3px;
}

.pr-3 {
	padding-right: 3rem;
}

.pr-4 {
	padding-right: 4rem;
}

.pr-5 {
	padding-right: 5rem;
}

.pr-6 {
	padding-right: 6rem;
}

.pb-0 {
	padding-bottom: 0rem;
}

.pb-1 {
	padding-bottom: 1rem;
}

.pb-2 {
	padding-bottom: 2rem;
}

.pb-3 {
	padding-bottom: 3rem;
}

.pb-4 {
	padding-bottom: 4rem;
}

.pb-5 {
	padding-bottom: 5rem;
}

.pb-6 {
	padding-bottom: 6rem;
}

.pl-0 {
	padding-left: 0rem;
}

.pl-1 {
	padding-left: 1rem;
}

.pl-2 {
	padding-left: 2rem;
}

.pl-3 {
	padding-left: 3rem;
}

.pl-4 {
	padding-left: 4rem;
}

.pl-5 {
	padding-left: 5rem;
}

.pl-6 {
	padding-left: 6rem;
}

.p-0-imp {
	padding: 0rem !important;
}

.p-1-imp {
	padding: 1rem !important;
}

.p-2-imp {
	padding: 2rem !important;
}

.p-3-imp {
	padding: 3rem !important;
}

.p-4-imp {
	padding: 4rem !important;
}

.p-5-imp {
	padding: 5rem !important;
}

.p-6-imp {
	padding: 6rem !important;
}

.pt-0-imp {
	padding-top: 0rem !important;
}

.pt-1-imp {
	padding-top: 1rem !important;
}

.pt-2-imp {
	padding-top: 2rem !important;
}

.pt-3-imp {
	padding-top: 3rem !important;
}

.pt-4-imp {
	padding-top: 4rem !important;
}

.pt-5-imp {
	padding-top: 5rem !important;
}

.pt-6-imp {
	padding-top: 6rem !important;
}

.pr-0-imp {
	padding-right: 0rem !important;
}

.pr-1-imp {
	padding-right: 1rem !important;
}

.pr-2-imp {
	padding-right: 2rem !important;
}

.pr-3-imp {
	padding-right: 3rem !important;
}

.pr-4-imp {
	padding-right: 4rem !important;
}

.pr-5-imp {
	padding-right: 5rem !important;
}

.pr-6-imp {
	padding-right: 6rem !important;
}

.pb-0-imp {
	padding-bottom: 0rem !important;
}

.pb-1-imp {
	padding-bottom: 1rem !important;
}

.pb-2-imp {
	padding-bottom: 2rem !important;
}

.pb-3-imp {
	padding-bottom: 3rem !important;
}

.pb-4-imp {
	padding-bottom: 4rem !important;
}

.pb-5-imp {
	padding-bottom: 5rem !important;
}

.pb-6-imp {
	padding-bottom: 6rem !important;
}

.pl-0-imp {
	padding-left: 0rem !important;
}

.pl-1-imp {
	padding-left: 1rem !important;
}

.pl-2-imp {
	padding-left: 2rem !important;
}

.pl-3-imp {
	padding-left: 3rem !important;
}

.pl-4-imp {
	padding-left: 4rem !important;
}

.pl-5-imp {
	padding-left: 5rem !important;
}

.pl-6-imp {
	padding-left: 6rem !important;
}

// display
.d-flex {
	display: flex;
}

.d-flex-imp {
	display: flex !important;
}

.flex-wrap {
	flex-wrap: wrap;
}

.justify-center {
	justify-content: center;
}

.justify-evenly {
	justify-content: space-evenly;
}

.justify-between {
	justify-content: space-between;
}

.justify-end {
	justify-content: flex-end;
}

.align-center {
	align-items: center;
}

.align-flex-end {
	align-items: flex-end;
}

.flex-column {
	flex-direction: column;
}

.d-none {
	display: none;
}

.d-block {
	display: block;
}

.align-self-end {
	align-self: flex-end;
}

.align-self-center {
	align-self: center;
}

.align-self-start {
	align-self: flex-start;
}

//width

.w-100 {
	width: 100%;
}

.w-100v {
	width: 100vw;
}

.w-50 {
	width: 50%;
}

.h-100 {
	height: 100%;
}

.h-100v {
	height: 100vh;
}

//alignment
.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.btn {
	position: relative;
	padding: 5px 22px;
	border: 0;
	cursor: pointer;
	border-radius: 2px;
	text-decoration: none;
	outline: none !important;
	-webkit-transition: 0.2s ease-out;
	-moz-transition: 0.2s ease-out;
	-o-transition: 0.2s ease-out;
	-ms-transition: 0.2s ease-out;
	transition: 0.2s ease-out;
}

.btn-sm {
	padding: 4px 8px !important;
	font-size: 12px !important;
}

.btn-default {
	color: #fff;
	background-color: #2bbbad;
}

.btn-default:hover,
.btn-default:focus {
	background-color: #30cfc0 !important;
	color: #fff !important;
}

.btn-primary {
	background-color: #4285f4;
}

.btn-primary:hover,
.btn-primary:focus {
	background-color: #5a95f5 !important;
	color: #fff;
}

.btn-success {
	background-color: #198754;
	width: 50px;
}

.btn-success:hover,
.btn-success:focus {
	background-color: #19875490 !important;
	color: #fff;
}

.btn-info {
	background-color: #03a9f4;
	width: 50px;

}

.btn-info:hover,
.btn-info:focus {
	background-color: #14b4fc !important;
	color: #fff;
}

.btn-warning {
	background-color: #ff5722;
	width: 50px;
}

.btn-warning:hover,
.btn-warning:focus {
	background-color: #ff6a3c !important;
	color: #fff;
}

.btn-danger {
	background-color: #d32f2f;
	width: 50px;
}

.btn-danger:hover,
.btn-danger:focus {
	background-color: #d74444 !important;
	color: #fff;
}
.btn-pink{
	background-color: rgb(217, 51, 203);
	color: white;
	width: 50px;
}
.btn-yellow{
	background-color: yellow;
	color: black;
	width: 50px;
}
.btn-white{
	background-color: white;
	color: black;
	width: 50px;
}

.fw-700 {
	font-weight: 700;
}

.fw-600 {
	font-weight: 600 !important;
}

.fw-500 {
	font-weight: 500;
}

.fw-400 {
	font-weight: 400;
}

.fs-10 {
	font-size: 10px;
}

.fs-12 {
	font-size: 12px;
}

.fs-14 {
	font-size: 14px;
}
.fs-16 {
	font-size: 16px;
}
.fs-18 {
	font-size: 18px;
}

.fs-20 {
	font-size: 20px;
}

.fs-24 {
	font-size: 24px;
}
.fs-32 {
	font-size: 32px;
}

.lh-16 {
	line-height: 16px;
}

.lh-20 {
	line-height: 20px;
}

.lh-22 {
	line-height: 22px;
}

.lh-24 {
	line-height: 24px;
}

.lh-26 {
	line-height: 26px;
}

.lh-32 {
	line-height: 32px;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import 'ngx-toastr/toastr';
@import './assets/scss/utilily.scss';


/* OLD

:root {
  --background: #000F08;
  --main-text: #ffffff;
  --complementary: #222222;
  --details: #9c66ef;
}
*/
/* color palette https://github.com/catppuccin/catppuccin */
// :root {
//   --clear-text: #eff1f5;
//   --crust: #11111b;
//   --base: #1e1e2e;
//   --mantle: #181825;
//   --surface0: #313244;
//   --surface1: #45475a;
//   --surface2: #585b70;
//   --overlay0: #6c7086;
//   --overlay1: #7f849c;
//   --overlay2: #9399b2;
//   --subtext0: #a6adc8;
//   --subtext1: #bac2de;
//   --text: #cdd6f4;
//   --lavender: #b4befe;
//   --blue: #89b4fa;
//   --sapphire: #74c7ec;
//   --sky: #89dceb;
//   --teal: #94e2d5;
//   --mauve: #cba6f7;
//   --red: #f38ba8;
//   --peach: #fab387;
//   --flamingo: #f2cdcd;

//   --main-text: var(--text);
//   --background: var(--base);
//   --complementary1: var(--surface0);
//   --complementary2: var(--surface1);
//   --details: var(--mauve);

//   --border-color: rgb(255, 255, 255, 0.1);
//   --new-bg: rgb(6, 11, 25)
// }

:root {
  --Crust: #11111b;
  --Base: #e6fbe8;
  --Mantle: #6b9e6a;
  --Surface0: #313244;
  --Surface1: #45475a;
  --Surface2: #585b70;
  --Overlay0: #6c7086;
  --Overlay1: #7f849c;
  --Overlay2: #9399b2;
  --Subtext0: #a6adc8;
  --Subtext1: #bac2de;
  --Text: #cdd6f4;
  --Lavender: #b4befe;
  --Blue: #89b4fa;
  --Sapphire: #74c7ec;
  --Sky: #89dceb;
  --Teal: #94e2d5;
  --Green: #a6e3a1;
  --Yellow: #f9e2af;
  --Peach: #fab387;
  --Maroon: #eba0ac;
  --Red: #f38ba8;
  --Mauve: #cba6f7;
  --Pink: #f5c2e7;
  --Flamingo: #f2cdcd;
  --Rosewater: #f5e0dc;
  --White : #ffffff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  color: var(--Text);
  letter-spacing: 2px;
}

html {
  background-color: var(--Base);
  color: var(--Text);
}

.home-section {
  position: relative;
  background: var(--Base);
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
  padding: 1% 5%
}

.sidebar.open~.home-section {
  left: 250px;
  width: calc(100% - 250px);
}

.home-section .text {
  display: inline-block;
  color: var(--Text);
  font-size: 25px;
  font-weight: 500;
  margin: 18px
}

.modal-dropbox {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(0,0,0,0.6);
  overflow: hidden;
}

.popup-window {
  z-index: 2;
}

@media (max-width: 420px) {
  .sidebar li .tooltip {
    display: none;
  }
}

.basic-table {
  padding: 1%;
  width: 100%;
  height: 100%;
}

.ag-theme-alpine-dark {
  --ag-background-color: var(--Mantle);
  --ag-odd-row-background-color: var(--Base);
  --ag-header-background-color: var(--Mantle);
  --ag-row-hover-color: var(--Green)
}

.ag-header-cell {
  background-color:var(--Base);
  color: white;
  font-weight: bold;
  /* Add any other styles you want to customize */
}
.semi-bold {  
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
}
select,input {
  font-size: 12px;
}
.d-inline-block {
  display: inline-block;
}
.ag-paging-row-summary-panel, .ag-icon.ag-icon-first, .ag-icon.ag-icon-last {
  display: none;
}
.ag-icon-previous::before {
  content: ''; 
  background-image: url('./assets/images/left.svg'); 
  background-size: cover;
  width: 16px; 
  height: 16px; 
  display: block; 
}
.ag-icon.ag-icon-next::before {
  content: ''; 
  background-image: url('./assets/images/right.svg'); 
  background-size: cover;
  width: 16px; 
  height: 16px; 
  display: block; 
}
.ag-paging-panel {
  background-color: var(--Base);
}
.ag-root-wrapper, .ag-header {
  border: none;
  border-radius: 15px;
}
#firstChartConrainer svg rect{
  fill: var(--ChartBg);
}
#firstChartConrainer_Series_0_Point_0,
#firstChartConrainer_Series_0_Point_1,
#firstChartConrainer_Series_0_Point_2,
#firstChartConrainer_Series_0_Point_3,
#firstChartConrainer_Series_0_Point_4,
#firstChartConrainer_Series_0_Point_5, 
#firstChartConrainer_Series_0_Point_6,
#firstChartConrainer_Series_0_Point_7 {
  fill: url('#gradient');
}
#secondChartConrainer svg rect{
  fill: var(--ChartBg);
}
  #secondChartConrainer_Series_0_Point_0,
  #secondChartConrainer_Series_0_Point_1,
  #secondChartConrainer_Series_0_Point_2,
  #secondChartConrainer_Series_0_Point_3,
  #secondChartConrainer_Series_0_Point_4, 
  #secondChartConrainer_Series_0_Point_5, 
  #secondChartConrainer_Series_0_Point_6,
  #secondChartConrainer_Series_0_Point_7 {
    fill: url('#gradient1');
  }
#thirdChartConrainer svg rect{
  fill: var(--ChartBg);
}
#thirdChartConrainer_Series_0_Point_0,
#thirdChartConrainer_Series_0_Point_1,
#thirdChartConrainer_Series_0_Point_2,
#thirdChartConrainer_Series_0_Point_3, 
#thirdChartConrainer_Series_0_Point_4, 
#thirdChartConrainer_Series_0_Point_5, 
#thirdChartConrainer_Series_0_Point_6,
#thirdChartConrainer_Series_0_Point_7 {
  fill: url('#gradient2');
}
.ag-row-odd .white-btn {
  background-color: #ffffff!important;
  color: #11111b;
}
// select2 css
span.select2.select2-container {
  font-size: 12px;
}
.select2-container--default .select2-selection--single {
  background-color: var(--Mantle);
  border: 1px solid var(--Overlay2);
  border-radius: 15px;
  padding: 0 10px;
  height: 38px;
  .select2-selection__rendered {
    color: var(--Text);
    line-height: 38px;
  }
  .select2-selection__arrow {
    height: 36px;
    right: 10px;
  }
}
.select2-dropdown {
  background-color: var(--Mantle);
  font-size: 12px;
  ul li{
    padding: 5px 10px;
  }
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  background-color: var(--Mantle);
  border: 1px solid var(--Overlay2)!important;
  outline: none;
}
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: var(--Base);
}
// toggle button
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-right: 5px;
  margin-left: 5px;
  input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 24px;
      width: 24px;
      left: 5px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
  input:checked + .slider {
    background-color: var(--Base);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
}
input[type="email"],
input[type="password"] {
  color: black;
}
// legends css
.legend-row .legend-box:last-child .legend-text {
	border: none;
}
.legend-row {
	display: flex;
	justify-content: center;
    width: 100%;
    padding: 25px 0;
    gap: 1rem;
	.legend-box {
		display: flex;
		align-items: center;
		justify-content: center;
		.legend-text {
			border-right: 1px solid;
			padding: 0 1rem;
		}
		.legend-circle {
			height: 20px;
			aspect-ratio: 1 / 1;
			border-radius: 50%;
			text-align: center;
			&.red {
				background-color: #d32f2f ;
			}
			&.white {
				background-color: #fff;
			}
			&.pink {
				background-color: #d933cb;
			}
			&.green {
				background-color: #198754;
			}
      &.yellow {
				background-color: yellow;
			}
		}
	}
	
}